import React, { useEffect, useState } from "react";
import {
  Button as ChakraButton,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useAuth } from "../src/contexts/AuthContext";
import { Alert } from "../src/Alert";
import { Logo } from "../src/components/Logo/Logo";
import { Button, CookieNotification } from "ui";

const errors = {
  accountCreationFailed:
    "Dein Account konnte leider nicht erstellt werden. Bitte versuche es später nochmal.",
  emailAndPasswordEmpty: "E-Mail und Passwort müssen befüllt werden.",
  passwordOrUserIncorrect:
    "Die angegebene E-Mail-Adresse oder das Passwort sind falsch",
  emailNotVerified:
    "Du hast deine E-Mail-Adresse noch nicht bestätigt. Bitte selektiere den Verifizierungslink in der Mail, die wir dir während der Registrierung zugesendet haben.",
} as const;

const Login = () => {
  const router = useRouter();
  const { login, logout, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reloadUser = async () => await currentUser?.reload();
    if (currentUser && currentUser.emailVerified === true) {
      reloadUser();
      router.push("/profile");
    }
  }, [currentUser]);

  const isEmpty = () => email === "" || password === "";

  const logoutWithNotVerfiedEmail = async () => {
    setError(errors.emailNotVerified);
    await logout();
  };

  const handleSubmit = async () => {
    if (isEmpty()) {
      setError(errors.emailAndPasswordEmpty);
      return;
    }

    if (currentUser && currentUser.emailVerified === false) {
      await logoutWithNotVerfiedEmail();
      return;
    }

    try {
      setError("");
      setLoading(true);

      await login(email.trim(), password.trim());

      // necessary check in case its the users first login attempt
      if (currentUser && currentUser.emailVerified === false) {
        await logoutWithNotVerfiedEmail();
        return;
      }

      router.push("/profile");
    } catch (error) {
      setError(errors.passwordOrUserIncorrect);
    }
    setLoading(false);
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <Logo
              imgUrl={"/logo/rgb/ML_logo_lndscp_rgb.svg"}
              width={120}
              height={60}
            />

            {error && <Alert status="error" title={error} />}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <FormControl id="email" isRequired mt={4}>
                <FormLabel>E-Mail-Adresse</FormLabel>
                <Input
                  variant={"filled"}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired mt={4}>
                <FormLabel>Passwort</FormLabel>
                <Input
                  type="password"
                  variant={"filled"}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={6}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  {/* <Link color={"blue.500"}>Passwort vergessen?</Link> */}
                </Stack>
                <VStack spacing={6}>
                  <ChakraButton
                    w={"100%"}
                    disabled={loading}
                    onSubmit={(e) => e.preventDefault()}
                    type="submit"
                    backgroundColor="#3532CE"
                    color="white"
                    _hover={{
                      backgroundColor: "gray.700",
                    }}
                    variant={"solid"}
                  >
                    Einloggen
                  </ChakraButton>
                  <ChakraButton
                    w={"100%"}
                    //backgroundColor="transparent"
                    color="gray.500"
                    _hover={{
                      color: "gray.700",
                    }}
                    variant={"link"}
                    onClick={() => router.push("/passwordReset")}
                  >
                    Passwort zurücksetzen
                  </ChakraButton>

                  <Divider />
                  <Button
                    type="link"
                    label="Zur Registrierung"
                    onClick={() => router.push("/signup")}
                  />
                </VStack>
              </Stack>
            </form>
          </Stack>
        </Flex>
        <Flex
          flex={1}
          style={{
            background:
              "linear-gradient(123deg, #FFFFFF 0%, #00B2FF 100%), linear-gradient(236deg, #BAFF99 0%, #005E64 100%), linear-gradient(180deg, #FFFFFF 0%, #002A5A 100%), linear-gradient(225deg, #0094FF 20%, #BFF4ED 45%, #280F34 45%, #280F34 70%, #FF004E 70%, #E41655 85%, #B30753 85%, #B30753 100%), linear-gradient(135deg, #0E0220 15%, #0E0220 35%, #E40475 35%, #E40475 60%, #48E0E4 60%, #48E0E4 68%, #D7FBF6 68%, #D7FBF6 100%)",
            backgroundBlendMode: "overlay, overlay, overlay, darken, normal",
          }}
        ></Flex>
      </Stack>
      <CookieNotification />
    </>
  );
};

export default Login;
