import React from "react";
import {
  Alert as CharkaAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Flex,
  Box,
} from "@chakra-ui/react";

interface IProps {
  status: "error" | "success";
  title: string;
  description?: string;
  onReset?: (
    param?: unknown
  ) => void | ((callback: (param?: unknown) => any) => void);
}

export const Alert: React.FC<IProps> = ({
  status,
  title,
  description,
  onReset,
}) => {
  return (
    <CharkaAlert status={status}>
      <Flex
        flexDir={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Flex>
          <AlertIcon />
          <AlertTitle mr={2}>{title}</AlertTitle>
          {description && <AlertDescription>{description}</AlertDescription>}
        </Flex>
        <Box>
          {onReset ? (
            <Button onClick={onReset} colorScheme={"red"}>
              Ok
            </Button>
          ) : null}
        </Box>
      </Flex>
    </CharkaAlert>
  );
};
