import React from "react";
import Image from "next/image";
interface ILogo {
  imgUrl: string;
  width: number;
  height: number;
}

export const Logo: React.FC<ILogo> = ({ imgUrl, width, height }) => {
  return (
    <Image src={imgUrl} alt="mentorlab logo" width={width} height={height} />
  );
};
